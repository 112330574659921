<template>
  <div class="right-item-one">
    <TitleMore :title="'资本影视'" :width="340"></TitleMore>
    <div
      v-for="item in movieList.slice(0, 2)"
      :key="item.id"
      @click="goVideoDetails(item.id)"
    >
      <img :src="BaseUrl + item.image" alt="" />
      <span>{{ item.title }}</span>
    </div>

    <div class="right-b">
      <div
        v-for="item in movieList.slice(2, 6)"
        :key="item.id"
        @click="goVideoDetails(item.id)"
      >
        <img src="../../../../../assets/indexImage/bofang.png" alt="" />
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import TitleMore from "../../../../../components/TitleMore.vue";
export default {
  name: "MovieRight",
  props: {
    movieList: [],
  },
  methods: {
    goVideoDetails(id) {
      this.$router.push(`/videodetails/${id}`);
    },
  },
  components: {
    TitleMore,
  },
};
</script>

<style lang="less" scoped>
.right-item-one {
  width: 340px;
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    > img:nth-child(1) {
      width: 340px;
      height: 205px;
      margin: 10px 0 19px 0;
    }
    > span:nth-child(2) {
      font-size: 18px;
      margin-bottom: 27px;
      color: #333333;
    }
  }
  > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    > img:nth-child(1) {
      width: 340px;
      height: 205px;
      margin: 10px 0 19px 0;
    }
    > span:nth-child(2) {
      font-size: 18px;
      margin-bottom: 27px;
      color: #333333;
    }
  }
  .right-b {
    div {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      cursor: pointer;
      > img:nth-child(1) {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      > span:nth-child(2) {
        font-size: 16px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
