<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2022-03-12 17:06:15
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-03-15 14:12:08
-->
<template>
  <div class="characters-left">
    <div class="left-top">
      <span>资本人物</span>
    </div>
    <div class="characters-all">
      <div class="characters-content">
        <div
          v-for="item in itemList.slice(0, 3)"
          :key="item.id"
          @click="goFiguredetails(item.id)"
        >
          <span>{{ item.title }}</span>
          <span :title="item.brief">{{ item.brief }}</span>
        </div>
      </div>
      <div class="characters-center">
        <div
          v-for="item in itemList.slice(4, 6)"
          :key="item.id"
          @click="goFiguredetails(item.id)"
        >
          <span>{{ item.title }}</span>
          <div>
            <img :src="BaseUrl + item.image" alt="" />
            <span :title="item.brief">{{ item.brief }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item-show">
      <div v-for="item in bottomList" :key="item.id">
        <!-- :href="item.linkurl"点击跳转返回回来的路径 -->
        <a :href="item.linkurl" target="_blank">
          <img :src="BaseUrl + item.image" alt="" />
          <span>{{ item.title }}</span>
        </a>
      </div>
    </div>
    <div class="index-rural">
      <div class="rural-title">
        <span>乡村振兴</span>
      </div>
      <div class="rural-content-left">
        <img :src="BaseUrl + xiangcunList[0].image" alt="" />
        <div>
          <div class="rural-item">
            <div
              class="item-list"
              v-for="item in xiangcunList.slice(0, 2)"
              :key="item.id"
              @click="goRuraldetails(item.id)"
            >
              <span :title="item.title">{{ item.title }}</span>
              <span :title="item.brief">{{ item.brief }}</span>
            </div>
          </div>
          <div>
            <span
              class="content"
              v-for="item in xiangcunList.slice(2, 12)"
              :key="item.id"
              @click="goRuraldetails(item.id)"
              >{{ item.title }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemList: [],
    bottomList: [],
    xiangcunList: [],
  },
  methods: {
    goFiguredetails(id) {
      this.$router.push(`/figuredetails/${id}`);
    },
    goRuraldetails(id) {
      this.$router.push(`/ruraldetails/${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.characters-left {
  margin-top: 55px;
  .left-top {
    width: 150px;
    font-size: 27px;
    padding-bottom: 20px;
    color: #3368ae;
    border-bottom: 3px solid #12407d;
    white-space: nowrap;
    margin-bottom: 43px;
    top: 100px;
  }
  .characters-all {
    width: 830px;
    display: flex;
    .characters-content {
      width: 399px;
      > div {
        width: 399px;
        display: flex;
        flex-direction: column;
        margin-bottom: 29px;
        border-bottom: 1px solid #dcdcdc;
        cursor: pointer;
        > span:nth-child(1) {
          margin-bottom: 21px;
          font-size: 20px;
          font-weight: bold;
          color: #333333;
        }
        > span:nth-child(1):hover {
          text-decoration: underline !important;
        }

        > span:nth-child(2) {
          display: block;
          margin-bottom: 9px;
          font-size: 15px;
          color: #666666;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
    .characters-center {
      margin-left: 30px;
      > div {
        margin-bottom: 50px;
        > span:nth-child(1) {
          font-size: 20px;
          color: #333333;
        }
        > span:nth-child(1):hover {
          text-decoration: underline !important;
        }
        > div:nth-child(2) {
          display: flex;
          margin-top: 21px;
          cursor: pointer;
          > img:nth-child(1) {
            width: 130px;
            height: 140px;
            margin-right: 16px;
          }
          > span:nth-child(2) {
            display: block;
            width: 229px;
            font-size: 15px;
            color: #333333;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            line-clamp: 7;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  .item-show {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    > div {
      width: 158px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      font-size: 17px;
      color: #333333;
      white-space: nowrap;
      > a {
        width: 158px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: #333333;
        > img {
          width: 158px;
          height: 112px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .index-rural {
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    .rural-title {
      width: 150px;
      font-size: 26px;
      padding-bottom: 20px;
      color: #3368ae;
      border-bottom: 3px solid #12407d;
      white-space: nowrap;
      margin-bottom: 43px;
    }
    .rural-content-left {
      display: flex;
      > img {
        width: 300px;
        height: 458px;
        margin-right: 29px;
      }
      > div:nth-child(2) {
        width: 495px;
        display: flex;
        flex-direction: column;
        .rural-item {
          .item-list {
            display: flex;
            flex-direction: column;
            > span:nth-child(1) {
              font-size: 22px;
              color: #333333;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              cursor: pointer;
            }
            > span:nth-child(1):hover {
              text-decoration: underline;
            }
            > span:nth-child(2) {
              font-size: 16px;
              color: #333333;
              margin: 15px 0 19px 0;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              cursor: pointer;
            }
          }
        }
        > div:nth-child(2) {
          display: flex;
          flex-wrap: wrap;
          .content {
            width: 237px;
            font-size: 16px;
            color: #333333;
            margin: 0px 10px 20px 0;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }
          .content::before {
            display: inline-block;
            content: "";
            width: 4px;
            height: 4px;
            background: #12407d;
            margin: 10px 6px 3px 0;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>
