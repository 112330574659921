<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2022-03-12 17:06:15
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-03-15 14:06:03
-->
<template>
  <div class="movie">
    <div class="movie-left-top" @click="goHotDetails(hotList[0].id)">
      <img :src="BaseUrl + hotList[0].image" alt="" />
      <span>{{ hotList[0].title }}</span>
    </div>
    <div class="movie-left-content">
      <div
        v-for="item in hotList.slice(1, 4)"
        :key="item.id"
        @click="goHotDetails(item.id)"
      >
        <span :title="item.title">{{ item.title }}</span>
        <span :title="item.brief">{{ item.brief }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hotList: [],
  },
  methods: {
    goHotDetails(id) {
      this.$router.push(`/hotdetails/${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.movie-content {
  margin-right: 30px;
  .movie-left-top {
    position: relative;
    width: 400px;
    height: 260px;
    margin-bottom: 59px;
    color: #ffffff;
    margin-top: 86px;
    cursor: pointer;
    > img:nth-child(1) {
      position: absolute;
      width: 400px;
      height: 260px;
      z-index: -1;
    }
    > span:nth-child(2) {
      position: absolute;
      width: 400px;
      height: 64px;
      bottom: 0px;
      text-align: center;
      line-height: 64px;
      font-size: 24px;
      background-color: rgba(0, 0, 0, 0.3);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-indent: 0.5em;
    }
  }
  .movie-left-content {
    width: 399px;
    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 29px;
      border-bottom: 1px solid #dcdcdc;
      cursor: pointer;
      > span:nth-child(1) {
        height: 52px;
        margin-bottom: 21px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      > span:nth-child(1):hover {
        text-decoration: underline !important;
      }
      > span:nth-child(2) {
        margin-bottom: 9px;
        font-size: 14px;
        color: #666666;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
