<template>
  <div class="index">
    <!-- 头部 -->
    <Header :logoShow="false" :currentIndex="0"></Header>
    <!-- 轮播图和热点 -->
    <div class="index-hot">
      <div class="hot-swiper">
        <el-carousel :interval="3000" arrow="always" height="438">
          <el-carousel-item v-for="item in itemObj.bannerList" :key="item.id">
            <img
              :src="BaseUrl + item.image"
              alt=""
              class="swiper-img"
              @click="goDayDetails(item.url, item.id)"
            />
            <span
              class="swiper-txt"
              :title="item.title"
              @click="goDayDetails(item.url, item.id)"
              >{{ item.title }}</span
            >
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="hot-right">
        <TitleMore :title="'今日热点'" :width="380" :show="false"></TitleMore>
        <div class="hot-content">
          <div
            class="content"
            v-for="item in itemObj.todayhotList"
            :key="item.id"
            @click="goDayDetails(item.url, item.id)"
          >
            <span class="content-title" :title="item.title">{{
              item.title
            }}</span>
            <span :title="item.brief">{{ item.brief }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 金刚区 -->
    <div class="index-project">
      <a
        :href="item.linkurl"
        v-for="(item, index) in itemObj.middleList"
        :key="index"
        target="_blank"
      >
        <img :src="BaseUrl + item.image" alt="" />
      </a>
    </div>

    <div class="index-movie">
      <div class="movie-left">
        <!-- 资本影视模块左侧 -->
        <MovieLeft
          :hotList="itemObj.hotList"
          @goHotDetails="goHotDetails"
        ></MovieLeft>
        <CharactersLeft
          :itemList="itemObj.renwuList"
          :bottomList="itemObj.bottomList"
          :xiangcunList="itemObj.xiangcunList"
        ></CharactersLeft>
      </div>
      <div class="movie-content">
        <!-- 资本影视模块中间 -->
        <!-- <MovieCenter :hotList="hotListRight"></MovieCenter>
        <CharactersCenter :itemList="itemObj.renwuList"></CharactersCenter> -->
      </div>
      <div class="movie-right">
        <!-- 资本影视模块右侧 -->
        <MovieRight :movieList="itemObj.yingshiList"></MovieRight>
        <CharactersRight
          :liveList="itemObj.shenghuoList"
          :newList="itemObj.newsList"
          :sideMiddle="itemObj.sideMiddle"
          :sideBottom="itemObj.sideBottom"
        ></CharactersRight>
      </div>
    </div>
    <!-- <div class="item-show">
        <div v-for="item in itemObj.bottomList" :key="item.id"> -->
    <!-- :href="item.linkurl"点击跳转返回回来的路径 -->
    <!-- <a :href="item.linkurl" target="_blank">
            <img :src="BaseUrl + item.image" alt="" />
            <span>{{ item.title }}</span>
          </a>
        </div>
      </div> -->
    <!-- <div class="index-rural">
        <div class="rural-title">
          <span>乡村振兴</span>
        </div>
        <div class="rural-content-left">
          <img :src="BaseUrl + itemObj.xiangcunList[0].image" alt="" />
          <div>
            <div class="rural-item">
              <div
                class="item-list"
                v-for="item in itemObj.xiangcunList.slice(0, 2)"
                :key="item.id"
                @click="goRuraldetails(item.id)"
              >
                <span :title="item.title">{{ item.title }}</span>
                <span :title="item.brief">{{ item.brief }}</span>
              </div>
            </div>
            <span
              class="content"
              v-for="item in itemObj.xiangcunList.slice(2, 8)"
              :key="item.id"
              @click="goRuraldetails(item.id)"
              >{{ item.title }}</span>
          </div>
        </div>
      </div> -->
    <Footer></Footer>
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
import TitleMore from "../../../components/TitleMore.vue";
import MovieLeft from "./IndexCom/Movie/MovieLeft.vue";
import MovieCenter from "./IndexCom/Movie/MovieCenter.vue";
import MovieRight from "./IndexCom/Movie/MovieRight.vue";
import CharactersLeft from "./IndexCom/Characters/CharactersLeft.vue";
import CharactersCenter from "./IndexCom/Characters/CharactersCenter.vue";
import CharactersRight from "./IndexCom/Characters/CharactersRight.vue";
export default {
  data() {
    return {
      itemObj: {},
      hotListLeft: [],
      hotListRight: [],
      currentItem: 0,
      imageUrl: "../../assets/indexImage/swiper.png",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http({
        url: "/index/index",
      }).then((res) => {
        console.log(res, "index");
        this.itemObj = res.data;
        for (var i = 0; i < this.itemObj.hotList.length; i++) {
          this.hotListRight.push(this.itemObj.hotList[2 * i + 1]);
        }
        for (var i = 0; i < this.itemObj.hotList.length; i++) {
          this.hotListLeft.push(this.itemObj.hotList[2 * i]);
        }
      });
    },
    // 首页大图跳转链接
    geturl(url) {
      window.location.href = "http://" + url;
    },
    changeItem(index) {
      this.currentItem = index;
    },
    // 跳转今日热点详情
    goDayDetails(url, id) {
      if (url == "anli/detail") {
        this.$router.push(`/figuredetails/${id}`);
      }
      if (url == "caijing/detail") {
        this.$router.push(`/hotdetails/${id}`);
      }
      if (url == "xingwen/detail") {
        this.$router.push(`/dynamicdetails/${id}`);
      }
      if (url == "lilun/detail") {
        this.$router.push(`/ruraldetails/${id}`);
      }
      if (url == "yingxiang/detail") {
        this.$router.push(`/videodetails/${id}`);
      }
      if (url == "todayhot/detail") {
        this.$router.push(`/daydetails/${id}`);
      }
      if (url == "live/detail") {
        this.$router.push(`/healthylifedetails/${id}`);
      }
      // /healthylifedetails/27
    },
    //跳转热点详情
    goHotDetails(id) {
      console.log(id);
      this.$router.push(`/hotdetails/${id}`);
    },
    // 跳转乡村振兴详情
    goRuraldetails(id) {
      this.$router.push(`/ruraldetails/${id}`);
    },
  },
  components: {
    Header,
    Footer,
    TitleMore,
    MovieLeft,
    MovieCenter,
    MovieRight,
    CharactersLeft,
    CharactersCenter,
    CharactersRight,
  },
};
</script>

<style lang="less" scoped>
/deep/.el-carousel__container {
  height: 438px !important;
}
/deep/.el-carousel__indicator--horizontal {
  display: none;
}
.index {
  width: 100%;
  .index-hot {
    width: 1200px;
    margin: 0 auto 51px;
    display: flex;
    .hot-swiper {
      position: relative;
      width: 780px;
      height: 438px;
      margin-right: 39px;
      .swiper-img {
        position: absolute;
        width: 780px;
        height: 438px;
        cursor: pointer;
      }
      .swiper-txt {
        width: 780px;
        height: 64px;
        line-height: 64px;
        position: absolute;
        bottom: 0;
        font-size: 24px;
        text-indent: 1.2em;
        background-color: rgba(0, 0, 0, 0.32);
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }
    .hot-right {
      width: 380px;
      .hot-content {
        .content {
          display: flex;
          flex-direction: column;
          cursor: pointer;
          > span:nth-child(1) {
            display: block;
            font-size: 21px;
            height: 50px;
            // line-height: 35px;
            // font-weight: blod;
            // margin-top: 12px;
            // margin-bottom: 12px;
            color: #333333;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-family: simsun, sans-serif;
            font-weight: 700;
          }
          > span:nth-child(1):hover {
            text-decoration: underline !important;
            // color: #12407d !important;
          }

          > span:nth-child(2) {
            font-size: 17px;
            color: #666666;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .index-project {
    width: 1200px;
    margin: 0 auto 59px;
    display: flex;
    flex-wrap: wrap;
    a {
      width: 380px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 15px 20px 15px 0px;
      > img {
        width: 380px;
        height: 200px;
      }
    }
  }
  .index-movie {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .movie-left {
      width: 830px;
      // height: 1200px;
      padding: 20px 0;
      margin-right: 30px;
    }
    .movie-right {
      width: 340px;
    }
  }
  .item-show {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    > div {
      width: 158px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      font-size: 17px;
      color: #333333;
      white-space: nowrap;
      > a {
        width: 158px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: #333333;
        > img {
          width: 158px;
          height: 112px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .index-rural {
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    .rural-title {
      width: 150px;
      font-size: 26px;
      padding-bottom: 20px;
      color: #3368ae;
      border-bottom: 3px solid #12407d;
      white-space: nowrap;
      margin-bottom: 43px;
    }
    .rural-content-left {
      display: flex;
      > img {
        width: 300px;
        height: 397px;
        margin-right: 29px;
      }
      > div:nth-child(2) {
        width: 495px;
        display: flex;
        flex-direction: column;
        .rural-item {
          .item-list {
            display: flex;
            flex-direction: column;
            > span:nth-child(1) {
              font-size: 22px;
              color: #333333;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              cursor: pointer;
            }
            > span:nth-child(1):hover {
              text-decoration: underline;
            }
            > span:nth-child(2) {
              font-size: 16px;
              color: #333333;
              margin: 15px 0 19px 0;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              cursor: pointer;
            }
          }
        }

        .content {
          font-size: 16px;
          color: #333333;
          margin: 0px 0 12px 0;
          cursor: pointer;
        }
        .content::before {
          display: inline-block;
          content: "";
          width: 4px;
          height: 4px;
          background: #12407d;
          margin: 0 6px 3px 0;
          border-radius: 50%;
        }
      }
    }
  }
}

.left-content {
  width: 399px;
  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 29px;
    border-bottom: 1px solid #dcdcdc;
    > span:nth-child(1) {
      margin-bottom: 21px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
    > span:nth-child(2) {
      margin-bottom: 9px;
      font-size: 15px;
      color: #666666;
    }
    > span:nth-child(3) {
      margin-bottom: 20px;
      font-size: 15px;
      color: #666666;
    }
  }
}
</style>
