<template>
  <div class="characters-right">
    <TitleMore :title="'资本动态'" :width="340"></TitleMore>
    <div class="characters-content">
      <div>
        <span
          v-for="item in newList"
          :key="item.id"
          @click="goDynamicDetail(item.id)"
          >{{ item.title }}</span
        >
      </div>
    </div>
    <div class="characters-footer">
      <a :href="sideMiddle[0].linkurl" target="_blank">
        <img :src="BaseUrl + sideMiddle[0].image" alt="" />
      </a>
    </div>
    <TitleMore :title="'健康生活'" :width="340"></TitleMore>
    <div class="characters-Life">
      <div
        class="Life-video"
        v-if="videoShow"
        @click="goHealthyDetails(liveList[0].id)"
      >
        <video
          src="https://zhilong.tchx9999.com/uploads/20211206/aa7cdbe27bd770484d367e63ec58f1d0.mp4"
          controls="controls"
          class="backG"
        ></video>
        <span class="tit" :title="liveList[0].title">{{
          liveList[0].title
        }}</span>
      </div>
      <div class="Life-img" @click="goHealthyDetails(liveList[0].id)">
        <img :src="BaseUrl + liveList[0].image" alt="" class="backG" />
        <span class="tit" :title="liveList[0].title">{{
          liveList[0].title
        }}</span>
      </div>
      <div>
        <span
          v-for="item in liveList.slice(1, 6)"
          :key="item.id"
          @click="goHealthyDetails(item.id)"
          >{{ item.title }}</span
        >
      </div>
    </div>
    <div class="characters-down">
      <a :href="sideBottom[0].linkurl" target="_blank">
        <img :src="BaseUrl + sideBottom[0].image" alt="" />
      </a>
    </div>
    <div class="characters-downn">
      <a :href="sideBottom[1].linkurl" target="_blank">
        <img :src="BaseUrl + sideBottom[1].image" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import TitleMore from "../../../../../components/TitleMore.vue";
export default {
  name: "CharactersRight",
  data() {
    return {
      videoShow: false,
    };
  },
  props: {
    newList: [],
    liveList: [],
    sideMiddle: [],
    sideBottom: [],
  },
  methods: {
    //跳转资本动态详情
    goDynamicDetail(id) {
      this.$router.push(`/dynamicdetails/${id}`);
    },
    //跳转健康生活详情
    goHealthyDetails(id) {
      this.$router.push(`/healthylifedetails/${id}`);
    },
  },
  components: {
    TitleMore,
  },
};
</script>

<style lang="less" scoped>
.characters-right {
  margin-top: 75px;
  .characters-content {
    > div {
      display: flex;
      flex-direction: column;
      > span {
        display: flex;
        margin-bottom: 16px;
        color: #333333;
        font-size: 16px;
        margin-right: 5px;
        cursor: pointer;
      }
      > span:hover {
        text-decoration: underline !important;
      }
      > span::before {
        display: block;
        content: "";
        width: 4px;
        height: 4px;
        background: #12407d;
        margin: 10px 6px 3px 0;
      }
    }
  }
  .characters-footer {
    margin: 39px 0 39px 0;
    a > {
      > img {
        width: 340px;
        height: 67px;
      }
    }
  }
  .characters-Life {
    .Life-img,
    .Life-video {
      position: relative;
      width: 340px;
      height: 250px;
      outline: none;
      margin-bottom: 16px;
      cursor: pointer;
      .backG {
        position: absolute;
        width: 340px;
        height: 250px;
      }
      .tit {
        position: absolute;
        bottom: 0;
        width: 340px;
        height: 40px;
        line-height: 40px;
        font-size: 26px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.3);
        text-indent: 0.5em;
      }
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      > span {
        font-size: 16px;
        color: #333333;
        margin-bottom: 14px;
        cursor: pointer;
      }
      > span:hover {
        text-decoration: underline !important;
      }
      > span::before {
        display: inline-block;
        content: "";
        width: 4px;
        height: 4px;
        background: #12407d;
        margin: 0 6px 3px 0;
        border-radius: 50%;
      }
    }
  }
  .characters-down {
    margin-top: 44px;
    > a {
      > img {
        width: 340px;
        height: 222px;
      }
    }
  }
  .characters-downn {
    margin-top: 30px;
    > a {
      > img {
        width: 340px;
        height: 157px;
      }
    }
  }
}
</style>
