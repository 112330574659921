<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2022-03-12 17:06:14
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-03-14 18:21:17
-->
<template>
    <div class="title" :style="{'width':width+'px'}">
        <span>{{title}}</span>
        <span @click="goPath" v-if="show">更多</span>
    </div>
</template>

<script>

export default {
    name:'TitleMore',
    props:{
        title:{
            type:String
        },
        width:{
            type:Number
        },
        show:{
            type:Boolean,
            default(){
                return true
            }
        }
    },
    methods:{
        goPath(){
           if(this.title == '今日热点'){
               this.$router.push('/hot')
           }else if(this.title =='资本影视'){
                this.$router.push('/video')
           }else if(this.title =='资本动态'){
                this.$router.push('/dynamic')
           }else if(this.title =='健康生活'){
                this.$router.push('/healthylife')
           }
        }
        
    }
}
</script>

<style lang='less' scoped>
.title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 380px;
    margin-bottom: 20px;
    height: 45px;
    color: #3368AE;
    font-size: 18px;
    border-bottom: 1px solid #DCDCDC;
    // margin-bottom: 18px;
    >span:nth-child(1){
        height: 50px;
        font-size: 27px;
        border-bottom: 3px solid #12407D;
        font-family:"Microsoft Yahei";
    }
    >span:nth-child(2){
       cursor: pointer;
    }
}
</style>