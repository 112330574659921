<template>
  <div class="movie">
    <div class="left-top">
      <span>资本热点</span>
    </div>
    <div class="imgItem">
      <div
        class="movie-left-top"
        @click="goHotDetails(item.id)"
        v-for="item in hotList.slice(0, 2)"
        :key="item.id"
      >
        <img :src="BaseUrl + item.image" alt="" />
        <span>{{ item.title }}</span>
      </div>
    </div>
    <div class="movie-left-content">
      <div
        v-for="item in hotList.slice(2)"
        :key="item.id"
        @click="goHotDetails(item.id)"
      >
        <span :title="item.title">{{ item.title }}</span>
        <span :title="item.brief">{{ item.brief }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [],
    };
  },
  props: {
    hotList: [],
  },
  methods: {
    goHotDetails(id) {
      this.$router.push(`/hotdetails/${id}`);
    },
  },
  created() {
    console.log(this.hotList);
  },
};
</script>

<style lang="less" scoped>
.movie-left {
  margin-right: 30px;
  .left-top {
    width: 150px;
    height: 30px;
    font-size: 27px;
    padding-bottom: 20px;
    color: #3368ae;
    border-bottom: 3px solid #12407d;
    white-space: nowrap;
    margin-bottom: 43px;
    margin-top: -20px;
  }
  .imgItem {
    display: flex;
    > div:nth-child(1) {
      margin-right: 30px;
    }
  }
  .movie-left-top {
    position: relative;
    width: 400px;
    height: 260px;
    // margin-right: 50px;
    margin-bottom: 59px;
    color: #ffffff;
    cursor: pointer;
    > img:nth-child(1) {
      position: absolute;
      width: 400px;
      height: 260px;
      z-index: -1;
    }
    > span:nth-child(2) {
      position: absolute;
      width: 400px;
      height: 64px;
      bottom: 0px;
      text-align: center;
      line-height: 64px;
      font-size: 24px;
      background-color: rgba(0, 0, 0, 0.3);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-indent: 0.5em;
    }
  }
  .movie-left-content {
    display: flex;
    flex-wrap: wrap;
    width: 830px;
    > div {
      width: 399px;
      display: flex;
      flex-direction: column;
      margin-bottom: 29px;
      border-bottom: 1px solid #dcdcdc;
      cursor: pointer;
      > span:nth-child(1) {
        margin-bottom: 21px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      > span:nth-child(1):hover {
        text-decoration: underline !important;
      }
      > span:nth-child(2) {
        margin-bottom: 9px;
        font-size: 14px;
        color: #666666;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    > div:nth-child(odd) {
      margin-right: 30px;
    }
  }
}
</style>
