<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2022-03-12 17:06:15
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-03-15 14:12:56
-->
<template>
  <div class="characters-center">
    <div
      v-for="item in itemList.slice(4, 6)"
      :key="item.id"
      @click="goFiguredetails(item.id)"
    >
      <span>{{ item.title }}</span>
      <div>
        <img :src="BaseUrl + item.image" alt="" />
        <span :title="item.brief">{{ item.brief }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CharactersCenter",
  props: {
    itemList: [],
  },
  methods: {
    goFiguredetails(id) {
      this.$router.push(`/figuredetails/${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.characters-center {
  margin-top: 155px;
  > div {
    margin-bottom: 50px;
    > span:nth-child(1) {
      font-size: 20px;
      color: #333333;
    }
    > span:nth-child(1):hover {
      text-decoration: underline !important;
    }
    > div:nth-child(2) {
      display: flex;
      margin-top: 21px;
      cursor: pointer;
      > img:nth-child(1) {
        width: 130px;
        height: 140px;
        margin-right: 16px;
      }
      > span:nth-child(2) {
        display: block;
        width: 229px;
        font-size: 15px;
        color: #333333;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        line-clamp: 7;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
